<div class="bo-toastr" [ngClass]="additionalClass">
  <bf-icon [size]="28">{{ icon }}</bf-icon>
  @if(title){

  <div [class]="options.titleClass" [attr.aria-label]="title">
    {{ title | translate }}
  </div>
  } @if(message){
  <div
    role="alert"
    class="bo-toastr__message"
    [attr.aria-label]="message"
    [innerHTML]="message | translate"
  ></div>
  }
</div>
