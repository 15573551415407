import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/components/header/header.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService, URLS } from '@core';
import { LanguageService } from '@shared/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent, MatTooltipModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly languageService = inject(LanguageService);
  private readonly authService = inject(AuthService);
  private readonly _destroy$ = new Subject<void>();

  showNavigation = signal(false);
  hiddenNavigationUrls = [`/${URLS.login}`, `/${URLS.register}`];
  showMenu = signal(false);

  ngOnInit(): void {
    this.languageService.initalizeDefaultLanguage();

    this.router.events.pipe(takeUntil(this._destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showMenu.set(false);
        const url = event.urlAfterRedirects;
        this.hiddenNavigationUrls.includes(url)
          ? this.showNavigation.set(false)
          : this.showNavigation.set(this.authService.isTokenValid());
      }
    });
  }

  closeMenu() {
    this.showMenu.set(false);
  }

  toggleMenu(): void {
    this.showMenu.set(!this.showMenu());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
