@if(showNavigation()) {
<app-header
  [showMenu]="showMenu()"
  (toggleMenu)="toggleMenu()"
  class="header"
/>
}

<div
  [class.main-content]="showNavigation()"
  [class.has-header]="showNavigation()"
>
  @if(showNavigation()) {
  <app-sidebar (menuClosed)="closeMenu()" [showMenu]="showMenu" />
  }
  <div class="h-full w-full">
    <div
      [class.main-content__inner]="showNavigation()"
      [class.has-header]="showNavigation()"
    >
      <router-outlet />

      @if(showNavigation()){
      <div class="mobile-spacing"></div>
      }
    </div>
  </div>
</div>
