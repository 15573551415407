import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/overview/overview.module').then(
        (m) => m.OverviewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'objects',
    loadChildren: () =>
      import('./features/objects/objects.module').then((m) => m.ObjectsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./features/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./features/company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'account/login',
  },
];
